/**
 * @module ViewApp
 */

import React from 'react'
import { Admin, Resource } from 'react-admin'
import { Dashboard } from 'views/dashboard'
import {
  ElevationList,
  ElevationShow,
  ElevationEdit,
  ElevationCreate,
} from 'views/elevations'
import {
  FeatureList,
  FeatureShow,
  FeatureEdit,
  FeatureCreate,
} from 'views/features'
import { TopicList } from 'views/topics'
import { createV3Theme } from '@youversion/react/styles'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import GradeIcon from '@material-ui/icons/Grade'
import dataProvider from 'clients/searchDataProvider'
import { useYouVersionAuth } from '@youversion/react/providers'
import createHistory from 'history/createBrowserHistory'

const theme = createV3Theme('light')

/**
 * App Authenticated react component.
 *
 * @returns {React.ReactElement} - The app.
 *
 */
export function AppAuthenticated() {
  const { signOut, getToken } = useYouVersionAuth()
  // React-Admin needs this authProvider
  const authProvider = {
    // called when the user attempts to log in
    login: () => {
      return Promise.resolve()
    },
    // called when the user clicks on the logout button
    logout: () => {
      signOut()
      return Promise.resolve()
    },
    // called when the API returns an error
    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        signOut()
        return Promise.reject()
      }
      return Promise.resolve()
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      return getToken ? Promise.resolve() : Promise.reject()
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
  }

  const history = createHistory()

  return (
    <>
      <Admin
        title="Seek"
        authProvider={authProvider}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        theme={theme}
        history={history}
        disableTelemetry={true}
      >
        <Resource
          name="elevations"
          list={ElevationList}
          show={ElevationShow}
          edit={ElevationEdit}
          create={ElevationCreate}
          icon={FilterHdrIcon}
        />
        <Resource
          name="features"
          list={FeatureList}
          show={FeatureShow}
          edit={FeatureEdit}
          create={FeatureCreate}
          icon={GradeIcon}
        />
        <Resource name="topics" list={TopicList} icon={ChatBubbleOutlineIcon} />
        <Resource name="videos" />
        <Resource name="verses" />
        <Resource name="plans" />
        <Resource name="churches" />
      </Admin>
    </>
  )
}
